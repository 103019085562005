/* Web Fonts */
@font-face {
    font-family: 'app-name-font';
    src: local('app-name-font'), 
        url('./fonts/montserratalternates-medium-webfont.woff2') format('woff2'),
        url('./fonts/montserratalternates-medium-webfont.woff') format('woff');
}
/* ------------------------------------------- */

.App {
    text-align: left;
}

.app-name-font {
    font-family: 'app-name-font';
}

.app-name-main {
    color: #fff !important;
    font-size: 2em;
}

.app-nav-bar {
    background-color: #579CDF !important;
}

.app-nav-bar-search-button {
    color: #fff !important;
    border: 1px solid #fff;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

.intro-text {
    text-align: left; 
    padding: 30px; 
    background-color: #E9ECEF;
}

.header-base {
    width: 100%;
    height: 10px;
    background-color: #579CDF;
}

.app-page {
    padding: 0 20px 20px 20px;
}

.page-container {
    margin: 0;
}

.category-card {
    /*box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.9);*/
    /*box-shadow: 0 0 8px #888888;*/
    border: none; 
    color: #ffffff; 
    width: 170px;
}

.subCategoryLinkMain button {
    color: #fff;
    text-decoration: none;
}
.subCategoryLinkMain button:hover {
    color: #fff;
}

@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
/* end App */

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-size: 16px;
    color: #333333;
}

a.global-link-color {
    color: #cf6d05;
}

a.global-text-decoration { 
    text-decoration: none;
}

.home-layout-main-image-container {
    text-align: center;
}

@media screen and (max-width: 479px) {
    article {
        padding: 1rem 3rem;
    }

    .home-layout-main {
        display: inline;
    }

    .home-layout-categories-container {
        display: none;
    }

    .home-layout-main p {
        padding: 20px;
    }

    .home-layout-main-image-container {
        width: 100%;
        text-align: center;
    }

    .home-layout-main-image-container img {
        width: 400px; 
    }
}